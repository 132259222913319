<script>
import { mapState, mapActions } from 'vuex'
import { VueGoodTable } from 'vue-good-table'

export default {
   components: {
      VueGoodTable,
   },
   data() {
      return {
         pdf: '',
         rows: [],
         columns: [
            {
               label: 'Nama',
               field: 'nama',
               sortable: false,
               thClass: 'text-center',
            },
            {
               label: 'Tanggal Terbit',
               field: 'tgl',
               sortable: false,
               thClass: 'text-center',
            },
            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center',
            },
         ],
      }
   },
   computed: {
      ...mapState({
         myItems: state => state.OssBidangUsaha.itemsIzinLama,
         myIdProyek: state => state.OssBidangUsaha.idProyek,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
      formatTanggal() {
         return tgl => {
            if (tgl) {
               return this.$moment(tgl).format('DD MMMM YYYY')
            }
            return '-'
         }
      },

   },
   watch: {
      isLoading(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
      myItems(items) {
         this.generateTable(items)
      },
      myIdProyek: {
         immediate: true,
         async handler(val) {
            await this.getIzinLama(val)
         },
      },

   },
   methods: {
      ...mapActions({
         getIzinLama: 'OssBidangUsaha/getIzinLama',
      }),
      generateTable(items) {
         items.map(item => {
            this.rows.push({ nama: item.nama_dokumen, tgl: item.tgl_terbit_dokumen, id_permohonan_izin: item.id_permohonan_izin })
            return null
         })
         // const tglLahir = this.$moment(this.myItem.tanggal_lahir).format('DD MMMM YYYY')
      },
      cetakIzin(id) {
         this.$emit('update:pdf', id)
      },
   },
}
</script>

<template>
   <div>
      <b-card>
         <vue-good-table
            ref="goodTable"
            :columns="columns"
            :rows="rows"
            :line-numbers="true"
            :pagination-options="{
               enabled: false,
            }"
         >
            <template slot="table-row" slot-scope="props">
               <span v-if="props.column.field == 'nama'">{{ props.row.nama }}</span>

               <span v-if="props.column.field == 'tgl'">{{ formatTanggal(props.row.tgl) }}</span>

               <span v-if="props.column.field == 'action'">
                  <center>
                     <b-button
                        size="sm"
                        variant="outline-dark"
                        @click="cetakIzin(props.row.id_permohonan_izin)"
                     >
                        <feather-icon icon="FileTextIcon" class="mr-50" />Lihat
                     </b-button>
                  </center>
               </span>
            </template>
         </vue-good-table>
      </b-card>
   </div>
</template>
