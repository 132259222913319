export default {
   /* eslint-disable key-spacing */
   1: 'Sintang',
   2: 'Kelam Permai',
   3: 'Dedai',
   4: 'Binjai Hulu',
   5: 'Sungai Tebelian',
   6: 'Sepauk',
   7: 'Tempunak',
   8: 'Serawai',
   9: 'Ambalau',
   10: 'Ketungau Hilir',
   11: 'Ketungau Hulu',
   12: 'Ketungau Tengah',
   13: 'Kayan Hilir',
   14: 'Kayan Hulu',
   /* eslint-enable key-spacing */
}
