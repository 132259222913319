export default {
   /* eslint-disable key-spacing */
   // '01': 'Perseroan Terbatas (PT)',
   // '02': 'Persekutuan Komanditer (CV)',
   '01': 'PT',
   '02': 'CV',
   '04': 'Badan Usaha Pemerintah',
   '05': 'Persekutuan Firma (Fa)',
   '07': 'Koperasi',
   '09': 'Persyarikatan atau Perkumpulan',
   10: 'Yayasan',
   14: 'Lembaga dan Bentuk Lainnya',
   17: 'Perorangan',
   18: 'Badan Layanan Umum (BLU)',
   19: 'Badan Hukum Lainnya',
   23: 'Perusda',
   26: 'PT Perorangan',
   /* eslint-enable key-spacing */
}
