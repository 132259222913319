import jenisPerusahaan from './modules/jenisPerusahaan'
import statusPm from './modules/statusPm'
import skalaUsaha from './modules/skalaUsaha'
import statusResiko from './modules/statusResiko'
import statusBadanHukum from './modules/statusBadanHukum'
import statusNib from './modules/statusNib'
import statusIzin from './modules/statusIzin'
import sektor from './modules/sektor'
import statusProyek from './modules/statusProyek'
import statusLkpm from './modules/statusLkpm'
import kewenanganIzin from './modules/kewenanganIzin'
import filterOss from './modules/filterOss'
import kecamatanIzinManual from './modules/kecamatanIzinManual'
import filterIzinManual from './modules/filterIzinManual'

export default {
   jenisPerusahaan,
   statusPm,
   skalaUsaha,
   statusResiko,
   statusBadanHukum,
   statusNib,
   statusIzin,
   sektor,
   statusProyek,
   statusLkpm,
   kewenanganIzin,
   filterOss,
   kecamatanIzinManual,
   filterIzinManual,
}
