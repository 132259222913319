const kecamatanlist = [
   'Amabalau',
   'Binjai Hulu',
   'Dedai',
   'Kayan Hilir',
   'Kayan Hulu',
   'Kelam Permai',
   'Ketungau Hilir',
   'Ketungau Hulu',
   'Ketungau Tengah',
   'Sepauk',
   'Serawai',
   'Sintang',
   'Sungai Tebelian',
   'Tempunak',
]

const jenisIzinRba = [
   { value: null, text: '-' },
   { value: 'izin', text: 'Izin' },
   { value: 'sertifikat standar', text: 'Sertifikat Standar' },
   { value: 'pkplh', text: 'PKPLH' },
   { value: 'pkkpr', text: 'PKKPR' },
   { value: 'skkl', text: 'SKKL' },
   { value: 'sppl', text: 'SPPL' },
]

const kewenanganIzinRba = [
   { value: null, text: '-' },
   { value: '00', text: 'Pusat' },
   { value: '01', text: 'Provinsi' },
   { value: '02', text: 'Kabupaten/Kota' },
]

const periodeTerbit = [
   { value: null, text: '-' },
   { value: 'bulan', text: 'Bulan' },
   { value: 'tahun', text: 'Tahun' },
]

const skalaUsaha = [
   { value: null, text: '-' },
   { value: '01', text: 'Mikro' },
   { value: '02', text: 'Kecil' },
   { value: '03', text: 'Menengah' },
   { value: '04', text: 'Besar' },
]

const resiko = [
   { value: null, text: '-' },
   { value: 'R', text: 'Rendah' },
   { value: 'MR', text: 'Menengah Renda' },
   { value: 'MT', text: 'Menengah Tinggi' },
   { value: 'T', text: 'Tinggi' },
]

const statusIzin = [
   { value: null, text: '-' },
   { value: '00', text: 'Menunggu verifikasi persyaratan' },
   { value: '47', text: '47' },
   { value: '50', text: 'Izin terbit/SS terverifikasi' },
   { value: '51', text: 'Masih Berlaku' },
   { value: '58', text: 'Terbit Otomatis' },
   { value: '93', text: '93' },
   { value: '99', text: 'Belum melengkapi persyaratan' },
]

const kecamatanOpt = [
   { value: null, text: '-' },
   { value: 'Ambalau', text: 'Ambalau' },
   { value: 'Binjai Hulu', text: 'Binjai Hulu' },
   { value: 'Dedai', text: 'Dedai' },
   { value: 'Kayan Hilir', text: 'Kayan Hilir' },
   { value: 'Kayan Hulu', text: 'Kayan Hulu' },
   { value: 'Kelam Permai', text: 'Kelam Permai' },
   { value: 'Ketungau Hilir', text: 'Ketungau Hilir' },
   { value: 'Ketungau Hulu', text: 'Ketungau Hulu' },
   { value: 'Ketungau Tengah', text: 'Ketungau Tengah' },
   { value: 'Sepauk', text: 'Sepauk' },
   { value: 'Serawai', text: 'Serawai' },
   { value: 'Sintang', text: 'Sintang' },
   { value: 'Sungai Tebelian', text: 'Sungai Tebelian' },
   { value: 'Tempunak', text: 'Tempunak' },
]

export default {
   kecamatanOpt,
   kecamatanlist,
   skalaUsaha,
   resiko,
   jenisIzinRba,
   kewenanganIzinRba,
   periodeTerbit,
   statusIzin,
}
