export default {
   /* eslint-disable key-spacing */
   '018': 'Pertanian',
   '019': 'Perindustrian',
   '023': 'Pendidikan dan Kebudayaan',
   '024': 'Kesehatan',
   '032': 'Kelautan dan Perikanan',
   '040': 'Pariwisata',
   '065': 'Badan Koordinasi Penanaman Modal',
   '090': 'Perdagangan',
   /* eslint-enable key-spacing */
}
