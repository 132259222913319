const kecamatan = [
   { value: null, text: '-' },
   { value: '1', text: 'Sintang' },
   { value: '2', text: 'Kelam Permai' },
   { value: '3', text: 'Dedai' },
   { value: '4', text: 'Binjai Hulu' },
   { value: '5', text: 'Sungai Tebelian' },
   { value: '6', text: 'Sepauk' },
   { value: '7', text: 'Tempunak' },
   { value: '8', text: 'Serawai' },
   { value: '9', text: 'Ambalau' },
   { value: '10', text: 'Ketungau Hilir' },
   { value: '11', text: 'Ketungau Hulu' },
   { value: '12', text: 'Ketungau Tengah' },
   { value: '13', text: 'Kayan Hilir' },
   { value: '14', text: 'Kayan Hulu' },
]

const periodeTerbit = [
   { value: null, text: '-' },
   { value: 'bulan', text: 'Bulan' },
   { value: 'tahun', text: 'Tahun' },
]

export default {
   kecamatan,
   periodeTerbit,
}
