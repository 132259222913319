<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import { SweetModal } from 'sweet-modal-vue'
import VuePdfApp from 'vue-pdf-app'
import PaginationCustom from '@/components/table/Pagination.vue'
import ProyekDetail from '@/components/oss/ProyekDetail.vue'
import IzinLama from '@/components/oss/IzinLama.vue'
import appData from '@/data'
import 'vue-pdf-app/dist/icons/main.css'

export default {
   name: 'NibDetail',
   components: {
      VueGoodTable,
      PaginationCustom,
      ProyekDetail,
      IzinLama,
      SweetModal,
      VuePdfApp,
   },
   data() {
      return {
         pdf: '',
         loading: true,
         loadingPdf: false,
         table1: [],
         table2: [],
         tableProfile: [],
         fields: [
            { key: 'name', label: 'Person full name', tdClass: 'text-left' },
            { key: 'value', label: 'Person full name', tdClass: 'text-left' },
         ],
         columns: [
            {
               label: 'Bidang Usaha',
               field: 'kbli',
               sortable: false,
               thClass: 'text-center',
               filterOptions: {
                  enabled: true,
                  placeholder: 'Cari KBLI',
               },
            },
            {
               label: 'Data Usaha',
               field: 'data_usaha',
               sortable: false,
               thClass: 'text-center',
            },
            {
               label: 'Kategori',
               field: 'kd_resiko',
               sortable: false,
               thClass: 'text-center',
            },
            {
               label: 'Status',
               field: 'status',
               sortable: false,
               thClass: 'text-center',
            },
            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center',
            },
         ],
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
      }
   },
   computed: {
      ...mapState({
         myNib: state => state.OssNib.item,
         myProfile: state => state.OssNib.itemProfile,
         myProyek: state => state.OssBidangUsaha.itemsNib,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
      jenisPerusahaan() {
         const data = appData.jenisPerusahaan
         return status => (data[status] ? data[status] : status)
      },
      skalaUsaha() {
         const data = appData.skalaUsaha
         return status => (data[status] ? data[status] : status)
      },
      statusPM() {
         const data = appData.statusPm
         return status => (data[status] ? data[status] : status)
      },
      statusResiko() {
         const data = appData.statusResiko
         return status => (data[status] ? data[status] : '-')
      },
      sektorCode() {
         const data = appData.sektor
         return status => (data[status] ? data[status] : status)
      },
   },
   watch: {
      myProfile() {
         this.generateTable()
      },
      loadingPdf(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
   },
   mounted() {
      if (typeof this.$route.params.id !== 'undefined') {
         this.getDataNib(this.$route.params.id)
            .then(result => {
               this.getDataProfile(result.id_profile).then(() => {
               })
            })
            .catch(error => {
               console.log(error)
            })

         this.getDataProyek(this.$route.params.id)
      } else {
         this.$router.push('/oss/nib')
      }
   },
   methods: {
      ...mapActions({
         getDataNib: 'OssNib/getItem',
         getDataProfile: 'OssNib/getProfileItem',
         getDataProyek: 'OssBidangUsaha/getItemsNib',
         cetakIzin: 'OssIzinRba/cetakIzin',
         cetakNib: 'OssNib/cetakNib',
         runScrap: 'OssScrap/runScrap',
         cetakIzinLama: 'OssBidangUsaha/cetakIzinLama',
         cetakReport: 'OssNib/cetakReport',
      }),
      ...mapMutations({
         setIdProyek: 'OssBidangUsaha/SET_ID_PROYEK',
      }),

      onDetailProyek(idProyek) {
         this.setIdProyek(idProyek)
         this.$bvModal.show('modal-detail')
      },
      onListIzinLama(idProyek) {
         this.setIdProyek(idProyek)
         this.$bvModal.show('modal-izin-lama')
      },
      onCetakReport(id) {
         this.loadingPdf = true
         this.cetakReport(id).then(res => {
            const blob = new Blob([res.data])
            this.pdf = URL.createObjectURL(blob)
            // URL.revokeObjectURL(objectURL)
            this.$refs.modalPdf.open()
            this.loadingPdf = false
         }, () => {
            this.loadingPdf = false
         })
      },
      onCetakIzin(id) {
         this.loadingPdf = true
         this.cetakIzin(id).then(res => {
            const blob = new Blob([res.data])
            this.pdf = URL.createObjectURL(blob)
            // URL.revokeObjectURL(objectURL)
            this.$refs.modalPdf.open()
            this.loadingPdf = false
         }, () => {
            this.loadingPdf = false
         })
      },
      onCetakIzinLama(id) {
         this.loadingPdf = true
         this.cetakIzinLama(id).then(res => {
            const blob = new Blob([res.data])
            this.pdf = URL.createObjectURL(blob)
            // URL.revokeObjectURL(objectURL)
            this.$refs.modalPdf.open()
            this.loadingPdf = false
         }, () => {
            this.loadingPdf = false
         })
      },
      onRunScrap(nib, idPermohonan) {
         this.loadingPdf = true
         this.runScrap(nib).then(() => {
            this.getDataNib(idPermohonan)
               .then(result => {
                  this.getDataProfile(result.id_profile).then(() => {
                  })
               })
               .catch(error => {
                  console.log(error)
               })

            this.getDataProyek(idPermohonan)
            this.loadingPdf = false
         }, () => {
            this.loadingPdf = false
         })
      },
      onCetakNib(nib) {
         this.loadingPdf = true
         this.cetakNib(nib).then(res => {
            const blob = new Blob([res.data])
            this.pdf = URL.createObjectURL(blob)
            // URL.revokeObjectURL(objectURL)
            this.$refs.modalPdf.open()
            this.loadingPdf = false
         }, () => {
            this.loadingPdf = false
         })
      },

      generateTable() {
         // RESET
         this.table1 = []
         this.table2 = []
         this.tableProfile = []

         // REFORMAT
         const tglTerbit = this.$moment(this.myNib.tanggal_terbit_oss).format('DD MMMM YYYY')
         const tglPerubahan = this.$moment(this.myNib.tanggal_perubahan_nib).format('DD MMMM YYYY')
         const tglAju = this.$moment(this.myNib.tanggal_aju).format('DD MMMM YYYY')
         const tglLahir = this.$moment(this.myProfile.tanggal_lahir).format('DD MMMM YYYY')

         // FILL TABLE
         this.table1.push({ name: 'No. Telp.', value: this.myNib.nomor_telp_perusahaan })
         this.table1.push({ name: 'Email', value: this.myNib.email_perusahaan })
         this.table1.push({ name: 'NPWP', value: this.myNib.npwp_perusahaan })
         this.table1.push({ name: 'Jenis Perusahaan', value: this.jenisPerusahaan(this.myNib.jenis_perusahaan) })
         this.table1.push({ name: 'Status PM', value: this.statusPM(this.myNib.status_penanaman_modal) })
         this.table1.push({ name: 'Skala Usaha', value: this.skalaUsaha(this.myNib.kd_skala_usaha_final) })
         this.table1.push({ name: 'Kode Pos', value: this.myNib.kode_pos_perusahaan })
         this.table1.push({ name: 'Alamat', value: this.myNib.alamat_perusahaan })

         this.table2.push({ name: 'Tanggal Terbit', value: tglTerbit })
         this.table2.push({ name: 'Tanggal Perubahan', value: tglPerubahan })
         this.table2.push({ name: 'Tanggal Pengajuan', value: tglAju })
         this.table2.push({ name: 'Modal', value: this.$options.filters.formatNumber(this.myNib.total_modal) })
         this.table2.push({ name: 'Modal Dasar', value: this.$options.filters.formatNumber(this.myNib.total_modal_dasar) })
         this.table2.push({ name: 'Modal Ditempatkan', value: this.$options.filters.formatNumber(this.myNib.total_modal_ditempatkan) })
         this.table2.push({ name: 'Modal Dalam Bentuk Uang', value: this.$options.filters.formatNumber(this.myNib.dalam_bentuk_uang) })
         this.table2.push({ name: 'BPJS Kesehatan (VA)', value: (this.myNib.bpjs_va || '-') })
         this.table2.push({ name: 'BPJS Tenaga Kerja (NPP)', value: (this.myNib.bpjs_npp || '-') })
         this.table2.push({ name: 'ID Permohonan', value: this.myNib.id_permohonan })

         this.tableProfile.push({ name: 'Jabatan', value: this.myProfile.jabatan })
         this.tableProfile.push({ name: 'Jenis Kelamin', value: this.myProfile.jenis_kelamin })
         this.tableProfile.push({ name: 'No. Telp', value: this.myProfile.telp })
         this.tableProfile.push({ name: 'Email', value: this.myProfile.email })
         this.tableProfile.push({ name: 'Status', value: this.myProfile.status_perkawinan })
         this.tableProfile.push({ name: 'Agama', value: this.myProfile.agama })
         this.tableProfile.push({ name: 'Pekerjaan', value: this.myProfile.pekerjaan })
         this.tableProfile.push({ name: 'Website', value: this.myProfile.website })
         this.tableProfile.push({ name: 'Nomor Identitas', value: this.myProfile.nomor_identitas })
         this.tableProfile.push({ name: 'Tempat Lahir', value: this.myProfile.tempat_lahir })
         this.tableProfile.push({ name: 'Tanggal Lahir', value: tglLahir })
         this.tableProfile.push({ name: 'Alamat', value: this.myProfile.alamat })
         this.tableProfile.push({ name: 'ID Profile', value: this.myProfile.id_profile })
      },

   },
}
</script>

<template>
   <div>
      <b-row>
         <b-col cols="12" md="8" xl="8" lg="8">
            <b-card text-variant="center" class="card card-congratulations">
               <!-- images -->
               <b-img
                  :src="require('@/assets/images/elements/decore-left.png')"
                  class="congratulations-img-left"
               />
               <b-img
                  :src="require('@/assets/images/elements/decore-right.png')"
                  class="congratulations-img-right"
               />
               <!--/ images -->

               <b-avatar variant="danger" size="70" class="shadow mb-2">
                  <feather-icon size="28" icon="FileTextIcon" />
               </b-avatar>
               <h2 class="mb-1 mt-50 text-white">
                  {{ myNib.nama_perusahaan || '-' }}
               </h2>
               <h3 class="mb-1 mt-50 text-white">
                  {{ myNib.nib || '-' }}
               </h3>
               <b-button-group class="mb-1">
                  <b-button variant="relief-secondary" @click="onCetakNib(myNib.nib)">
                     <feather-icon icon="FileTextIcon" class="mr-50" />Cetak NIB
                  </b-button>
                  <b-button variant="relief-secondary" @click="onCetakReport(myNib.nib)">
                     <feather-icon icon="FileTextIcon" class="mr-50" />Report
                  </b-button>
                  <b-button
                     variant="relief-secondary"
                     @click="onRunScrap(myNib.nib, myNib.id_permohonan)"
                  >
                     <feather-icon icon="PlayIcon" class="mr-50" />Update
                  </b-button>
               </b-button-group>

               <br>
               <br>
               <b-row>
                  <b-col cols="6" lg="6" sm="12">
                     <b-table
                        hover
                        small
                        :items="table1"
                        :fields="fields"
                        thead-class="hidden_header"
                        tbody-class="text-white"
                     />
                  </b-col>
                  <b-col cols="6" lg="6" sm="12">
                     <b-table
                        hover
                        small
                        :items="table2"
                        :fields="fields"
                        thead-class="hidden_header"
                        tbody-class="text-white"
                     />
                  </b-col>
               </b-row>
            </b-card>
         </b-col>
         <b-col cols="12" xl="4" lg="4" md="4">
            <b-card title="Profile PJ">
               <b-row>
                  <b-col cols="21" xl="12" class="mb-2">
                     <div class="d-flex align-items-center mr-2">
                        <b-avatar variant="light-primary" rounded>
                           <feather-icon icon="user" size="18" />
                        </b-avatar>
                        <div class="ml-1">
                           <h5 class="mb-0">
                              {{ myProfile.nama }}
                           </h5>
                        </div>
                     </div>
                  </b-col>
               </b-row>
               <b-table
                  hover
                  small
                  :items="tableProfile"
                  :fields="fields"
                  thead-class="hidden_header"
               />
            </b-card>
         </b-col>
      </b-row>
      <b-row>
         <b-col cols="12">
            <b-card title="Data Bidang Usaha">
               <form>
                  <vue-good-table
                     ref="goodTable"
                     :columns="columns"
                     :rows="myProyek"
                     :line-numbers="true"
                     :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                     }"
                  >
                     <template slot="table-row" slot-scope="props">
                        <!-- Column: Name -->

                        <span v-if="props.column.field == 'kbli'">
                           <b>KBLI : {{ props.row.kbli }}</b>
                           <hr>
                           <small>{{ props.row.kbli_nama || '-' }}</small>
                           <hr>
                           <small>
                              Nama :
                              <b>{{ sektorCode(props.row.nama_proyek) }}</b>
                           </small>
                           <hr>
                           <small>
                              Sektor :
                              <b>{{ sektorCode(props.row.sektor) }}</b>
                           </small>
                           <hr>
                           <small>
                              Alamat :
                              <b>{{ sektorCode(props.row.alamat_usaha) }}</b>
                           </small>
                           <hr>
                           <small>{{ props.row.id_proyek }}</small>
                        </span>

                        <span v-if="props.column.field == 'data_usaha'">
                           <table class="borderless">
                              <tr>
                                 <td>
                                    <small>Modal</small>
                                 </td>
                                 <td>
                                    <small>&nbsp;:&nbsp;</small>
                                 </td>
                                 <td>
                                    <b class="text-danger">
                                       <small>{{ props.row.jumlah_investasi | formatNumber }}</small>
                                    </b>
                                 </td>
                              </tr>
                              <tr>
                                 <td>
                                    <small>TKI</small>
                                 </td>
                                 <td>
                                    <small>&nbsp;:&nbsp;</small>
                                 </td>
                                 <td>
                                    <small>{{ (props.row.jumlah_tenaga_kerja) }}</small>
                                 </td>
                              </tr>
                              <tr>
                                 <td>
                                    <small>TKA</small>
                                 </td>
                                 <td>
                                    <small>&nbsp;:&nbsp;</small>
                                 </td>
                                 <td>
                                    <small>{{ (props.row.jumlah_tenaga_kerja_asing) }}</small>
                                 </td>
                              </tr>
                              <tr>
                                 <td>
                                    <small>Migrasi</small>
                                 </td>
                                 <td>
                                    <small>&nbsp;:&nbsp;</small>
                                 </td>
                                 <td>
                                    <small>{{ (props.row.flag_migrasi) }}</small>
                                 </td>
                              </tr>
                           </table>
                        </span>

                        <span v-if="props.column.field == 'kd_resiko'">
                           <table class="borderless">
                              <tr>
                                 <td>Resiko</td>
                                 <td>&nbsp;:&nbsp;</td>
                                 <td>
                                    <b class="text-danger">{{ statusResiko(props.row.kd_resiko) }}</b>
                                 </td>
                              </tr>
                              <tr>
                                 <td>Skala</td>
                                 <td>&nbsp;:&nbsp;</td>
                                 <td>{{ skalaUsaha(props.row.kd_skala_usaha) }}</td>
                              </tr>
                           </table>
                        </span>

                        <span v-if="props.column.field == 'status'">
                           <center>{{ (props.row.status) }}</center>
                        </span>

                        <span v-if="props.column.field == 'action'">
                           <center>
                              <b-button
                                 size="sm"
                                 block
                                 variant="outline-dark"
                                 @click="onDetailProyek(props.row.id_proyek)"
                              >
                                 <small>Detail</small>
                              </b-button>
                              <b-button
                                 size="sm"
                                 block
                                 variant="outline-dark"
                                 @click="onListIzinLama(props.row.id_proyek)"
                              >
                                 <small>Izin Lama - {{ props.row.OssIzinLama.length }}</small>
                              </b-button>
                              <b-button
                                 v-for="item in props.row.OssIzin"
                                 :key="item.id"
                                 size="sm"
                                 block
                                 variant="primary"
                                 @click="onCetakIzin(item.id_permohonan_izin)"
                              >
                                 <feather-icon icon="FileTextIcon" class="mr-50" />
                                 <small>{{ item.nama_dokumen }}</small>
                              </b-button>
                           </center>
                        </span>

                     <!-- Column: Common -->
                     <!-- <span v-else>{{ props.formattedRow[props.column.field] }}</span> -->
                     <!-- <span v-else>{{ props.formattedRow[props.column.field] }}</span> -->
                     </template>

                     <!-- pagination -->
                     <template slot="pagination-bottom" slot-scope="props">
                        <pagination-custom
                           :total="props.total"
                           :page-length="pageLength"
                           :page-options="pageOptions"
                           :page-changed="props.pageChanged"
                           :per-page-changed="props.perPageChanged"
                        />
                     </template>
                  </vue-good-table>
               </form>
            </b-card>
         </b-col>
      </b-row>

      <b-modal
         id="modal-detail"
         scrollable
         size="lg"
         centered
         hide-footer
         title="Bidang Usaha Detail"
      >
         <ProyekDetail ref="proyekDetail" />
      </b-modal>

      <b-modal
         id="modal-izin-lama"
         scrollable
         size="lg"
         centered
         hide-footer
         title="Izin Lama"
      >
         <IzinLama ref="izinLama" @update:pdf="onCetakIzinLama" />
      </b-modal>

      <sweet-modal ref="modalPdf" modal-theme="light" overlay-theme="dark" width="80%">
         <!-- <pdf :src="pdf" /> -->
         <div style="height: 70vh;">
            <vue-pdf-app :pdf="pdf" />
         </div>
      </sweet-modal>
   </div>
</template>

<style scoped>
.borderless > tr > td {
   border: none;
   padding: 0em;
}
table > tbody > tr > td > span > hr {
   margin-top: 0rem;
   margin-bottom: 0rem;
}
</style>

<style>
.hidden_header {
   display: none;
}
</style>
