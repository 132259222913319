<script>
import { mapState, mapActions } from 'vuex'
import appData from '@/data'

export default {
   // props: {
   //    idProyek: {
   //       required: false,
   //       type: String,
   //    },
   // },
   data() {
      return {
         item: {},
         table1: [],
         table2: [],
         fields: [
            { key: 'name', label: 'Person full name', tdClass: 'text-left' },
            { key: 'value', label: 'Person full name', tdClass: 'text-left' },
         ],
      }
   },
   computed: {
      ...mapState({
         myItem: state => state.OssBidangUsaha.item,
         myIdProyek: state => state.OssBidangUsaha.idProyek,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
      skalaUsaha() {
         const data = appData.skalaUsaha
         return status => (data[status] ? data[status] : status)
      },
      statusResiko() {
         const data = appData.statusResiko
         return status => (data[status] ? data[status] : '-')
      },
   },
   watch: {
      // idProyek: {
      //    immediate: true,
      //    async handler(val) {
      //       await this.getItem(val).then(() => { this.item = this.myItem })
      //    },
      // },
      isLoading(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
      myItem(item) {
         this.generateTable(item)
      },
      myIdProyek: {
         immediate: true,
         async handler(val) {
            await this.getItem(val).then(() => { this.item = this.myItem })
         },
      },
   },
   methods: {
      ...mapActions({
         getItem: 'OssBidangUsaha/getItem',
      }),
      generateTable(item) {
         const created = this.$moment(item.created).utc().format('DD MMMM YYYY')
         const updated = this.$moment(item.updated).utc().format('DD MMMM YYYY')

         this.table1.push({ name: 'Nama Usaha / Kegiatan', value: item.nama_proyek })
         this.table1.push({ name: 'KBLI', value: item.kbli })
         this.table1.push({ name: 'Judul KBLI', value: item.kbli_nama })
         this.table1.push({ name: 'Sektor', value: item.sektor })
         this.table1.push({ name: 'TKI', value: (`${item.jumlah_tenaga_kerja} Orang`) })
         this.table1.push({ name: 'TKA', value: (`${item.jumlah_tenaga_kerja_asing} Orang`) })
         this.table1.push({ name: 'Dibuat', value: created })
         this.table1.push({ name: 'Diubah', value: updated })

         this.table2.push({ name: 'Resiko', value: this.statusResiko(item.kd_resiko) })
         this.table2.push({ name: 'Skala Usaha', value: this.skalaUsaha(item.kd_skala_usaha) })
         this.table2.push({ name: 'Jumlah Investasi', value: this.$options.filters.formatNumber(item.jumlah_investasi) })

         this.table2.push({ name: 'Status', value: item.status })
         this.table2.push({ name: 'Status Syarat', value: item.status_pemenuhan_syarat })
         this.table2.push({ name: 'Status Pernyataan', value: item.status_pernyataan })
         this.table2.push({ name: 'Id Proyek', value: item.id_proyek })
         this.table2.push({ name: 'Luas Lahan Usaha', value: item.luas_tanah })
         this.table2.push({ name: 'Alamat Usaha', value: item.alamat_usaha })
      },
   },
}
</script>

<template>
   <b-card text-variant="center" class="card card-congratulations">
      <b-avatar variant="danger" size="70" class="shadow mb-2">
         <feather-icon size="28" icon="FileTextIcon" />
      </b-avatar>
      <h2 class="mb-1 mt-50 text-white">
         {{ myItem.nama_perusahaan }}
      </h2>
      <h3 class="mb-1 mt-50 text-white">
         {{ myItem.nib }}
      </h3>
      <br>
      <br>
      <b-row>
         <b-col cols="6" lg="6" sm="12">
            <b-table
               ref="table1"
               hover
               small
               :items="table1"
               :fields="fields"
               thead-class="hidden_header"
               tbody-class="text-white"
            />
         </b-col>
         <b-col cols="6" lg="6" sm="12">
            <b-table
               hover
               small
               :items="table2"
               :fields="fields"
               thead-class="hidden_header"
               tbody-class="text-white"
            />
         </b-col>
      </b-row>
   </b-card>
</template>

<style>
.hidden_header {
   display: none;
}
</style>
