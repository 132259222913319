export default {
   /* eslint-disable key-spacing */
   '00': 'Menunggu verifikasi persyaratan',
   47: '47',
   50: 'Izin terbit/SS terverifikasi',
   51: 'Masih Berlaku',
   58: 'Terbit Otomatis',
   93: '93',
   99: 'Belum melengkapi persyaratan',
   /* eslint-enable key-spacing */
}
